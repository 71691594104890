            <a-menu-item key="attach_cic">Attach CIC</a-menu-item>
<template>
  <div>
    <div class="popover-container">
      <div class="popover-title">Vacancy Status</div>
      <div class="popover-main-section">
        <div class="vacancy-status-select-option">
          <div>
            <span class="input-label">Vacancy Status</span>
            <span class="required-input">*</span>
          </div>
          <a-select
            v-model:value="vacancyStatusId"
            class="select-input"
            :options="vacancy_status_options"
          >
          </a-select>
        </div>
        <div class="action-button-section">
          <a-button
            class="cancel-button"
            type="link"
            @click="closeChageVacancyStage"
            >Cancel</a-button
          >
          <a-button class="save-button" @click="updateVacancies">Save</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Services from "../../../services/apis";
import { ref, onMounted, computed } from "vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
export default {
  setup(props, context) {
    const store = useStore();
    let vacancyStatusId = ref("");
    let vacancy_status_options = ref([]);
    const fetchVacancyStatusList = (value) => {
      let payload = {};
      payload = { q: "", mode: "AUTOCOMPLETE", types: ["VACANCY"] };
      Services.getStageDetail(payload)
        .then((response) => {
          const { data } = response;
          vacancy_status_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const updateVacancies = () => {
      let vacancyList = computed(
        () => store.getters["vacancyStore/getSelectedVacancyIds"]
      ).value;
      let updateArray = vacancyList.map((vacancyId) => {
        let updateObject = {
          vacancyId: vacancyId,
          statusId: vacancyStatusId.value,
        };
        return updateObject;
      });
      Services.vacancyBulkUpdates(updateArray)
        .then((response) => {
          if (response.status === 200) {
            updateArray.splice(0);
            message.success("Vacancy Status Updated Successfully");
            context.emit("closeVacancyStatus", false, response.data.success);
          }
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const closeChageVacancyStage = () => {
      context.emit("closeVacancyStatus", false);
    };
    onMounted(() => {
      fetchVacancyStatusList();
    });
    return {
      vacancyStatusId,
      vacancy_status_options,
      fetchVacancyStatusList,
      updateVacancies,
      closeChageVacancyStage,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/changeVacancyStage.scss";
</style>
