<template>
  <div class="main-container">
    <div class="candidate-filter-header-text">
      <span>Filter by</span>
      <a-button type="link" @click="resetFields">Reset Changes</a-button>
    </div>
    <div class="filter-container">
      <a-form>
        <div class="filter-personal-details" v-if="false">
          <div class="filter-personal-details-form">
            <div class="title">Personal Details</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 33%"
              >
                <a-form-item>
                  <a-input
                    v-model:value="modelRef.phone"
                    class="form-input"
                    placeholder="Enter Phone Number"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements left-form-elements"
                style="flex-basis: 30%; margin-right: 0"
              >
                <a-form-item>
                  <a-input
                    v-model:value="modelRef.email"
                    class="form-input"
                    placeholder="Enter Email"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 30%">
                <a-form-item>
                  <a-input
                    v-model:value="modelRef.name"
                    class="form-input"
                    placeholder="Enter Name"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 6.5rem"
          />
        </div>
        <div class="vacancy-details">
          <div class="vacancy-details-form">
            <div class="title">Vacancy</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 34%"
              >
                <a-form-item>
                  <span class="input-label">Vacancy ID</span>
                  <a-input
                    v-model:value="modelRef.vacancyId"
                    class="form-input"
                  >
                  </a-input>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 65%">
                <a-form-item>
                  <span class="input-label">Vacancy Title</span>
                  <a-input
                    v-model:value="modelRef.title"
                    class="form-input"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 34%"
              >
                <a-form-item>
                  <span class="input-label">Vacancy Status</span>
                  <a-select
                    class="filter-select"
                    v-model:value="modelRef.status"
                    :filter-option="false"
                    :options="vacancy_status_options"
                    @search="fetchVacancyStatusData"
                  ></a-select>
                </a-form-item>
              </div>
              <div style="display: flex; flex-basis: 57%">
                <div class="filter-form-elements" style="flex-basis: 33%">
                  <a-form-item>
                    <span class="input-label">Vacancy Type</span>
                    <a-select
                      class="filter-select"
                      mode="tags"
                      label-in-value
                      v-model:value="modelRef.types"
                      :filter-option="false"
                      :options="vacancy_type_options"
                      @search="fetchVacancyTypeData"
                    ></a-select>
                  </a-form-item>
                </div>
                <div
                  class="filter-form-elements"
                  style="flex-basis: 33%; margin: 0 0.5rem !important"
                >
                  <a-form-item>
                    <span class="input-label">From Date</span>
                    <a-date-picker
                      class="date-input"
                      v-model:value="modelRef.fromDate"
                      style="padding-top: 0.65rem"
                    ></a-date-picker>
                  </a-form-item>
                </div>
                <div class="filter-form-elements" style="flex-basis: 33%">
                  <a-form-item>
                    <span class="input-label">To Date</span>
                    <a-date-picker
                      class="date-input"
                      v-model:value="modelRef.toDate"
                      style="padding-top: 0.65rem; margin-right: 1rem"
                      placeholder="To Date"
                    ></a-date-picker>
                  </a-form-item>
                </div>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 53.5%"
              >
                <a-form-item>
                  <span class="input-label">Lead CIC</span>
                  <a-select
                    show-search
                    class="filter-select"
                    style="width: 100%"
                    mode="tags"
                    v-model:value="modelRef.leadCics"
                    :filter-option="false"
                    :options="lead_cic_options"
                    @search="fetchLeadCIC"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 37.5%">
                <a-form-item>
                  <span class="input-label">Originated By</span>
                  <a-select
                    show-search
                    style="width: 100%"
                    class="filter-select"
                    :options="originated_by_options"
                    @search="fetchOriginatedBy"
                    v-model:value="modelRef.originatedBy"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 20.5rem"
          />
        </div>
        <div class="commpany-taxonomy-details">
          <div class="commpany-taxonomy-details-form">
            <div class="title">Company Taxonomy</div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 46%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Sector"
                    v-model:value="modelRef.sectors"
                    :filter-option="false"
                    :options="sector_tags_options"
                    @search="fetchSectorData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 46%">
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Industry"
                    v-model:value="modelRef.industries"
                    :filter-option="false"
                    :options="industry_tags_options"
                    @search="fetchIndustryData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="flex-basis: 95.5%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    placeholder="Select Vertical"
                    v-model:value="modelRef.verticals"
                    mode="tags"
                    :filter-option="false"
                    :options="verticals_tags_options"
                    @search="fetchVerticalData"
                    @change="fetchpracticeAreas('', modelRef.verticals)"

                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="flex-basis: 46%; margin-left: 1rem; width: 100%"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    v-model:value="modelRef.practiceAreas"
                    mode="tags"
                    placeholder="Select Practice Area"
                    :filter-option="false"
                    :options="practice_area_tags_options"
                    @search="fetchpracticeAreas"
                    @change="fetchsubPracticeAreas('', modelRef.practiceAreas)"

                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 46%">
                <a-form-item>
                  <a-select
                    v-model:value="modelRef.subPracticeAreas"
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Sub Practice Area"
                    :filter-option="false"
                    :options="sub_practice_area_tags_options"
                    @search="fetchsubPracticeAreas"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="sub-section-diveder" />
            <div class="form-sub-section">
              <div
                style="
                  flex-basis: 31%;
                  margin-left: 1rem;
                  width: 100%;
                  margin-right: 5px;
                "
                class="filter-form-elements"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Function"
                    v-model:value="modelRef.functions"
                    :filter-option="false"
                    :options="function_tags_options"
                    @search="fetchFunctionData"
                  ></a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements"
                style="flex-basis: 31%; margin-right: 5px"
              >
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Sub Function"
                    v-model:value="modelRef.subFunctions"
                    :filter-option="false"
                    :options="sub_function_tags_options"
                    @search="fetchSubFunctionData"
                  ></a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 31%">
                <a-form-item>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    placeholder="Select Sub Sub Function"
                    v-model:value="modelRef.subSubFunctions"
                    :filter-option="false"
                    :options="sub_sub_function_tags_options"
                    @search="fetchSubSubFunctionData"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 31%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Company Type</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    v-model:value="modelRef.companyTypes"
                    :filter-option="false"
                    :options="company_types_tags_options"
                    @search="fetchCompanyTypeData"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div
                style="flex-basis: 31%"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Company Name</span>
                  <a-select
                    show-search
                    style="width: 100%"
                    class="filter-select"
                    label-in-value
                    :options="company_name_options"
                    @search="fetchCompanyData"
                    v-model:value="modelRef.companyName"
                    :filter-option="false"
                    showArrow
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <a-divider class="section-divider" />
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 35.5rem"
          />
        </div>
        <div class="lorem-ipsum-section">
          <div class="lorem-ipsum-section-form">
            <div class="title">Lorem Ipsum</div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">Degree</span>
                  <a-select
                    show-search
                    style="width: 100%"
                    class="filter-select"
                    :options="education_degrees_options"
                    @search="fetchDegreeData"
                    v-model:value="modelRef.degrees"
                    :filter-option="false"
                    showArrow
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 61%">
                <a-form-item>
                  <span class="input-label">Institute</span>
                  <a-select
                    show-search
                    style="width: 100%"
                    class="filter-select"
                    :options="university_options"
                    @search="fetchInstitutesData"
                    v-model:value="modelRef.institutes"
                    :filter-option="false"
                    showArrow
                  >
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                class="filter-form-elements left-form-elements"
                style="margin-left: 1rem; flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">Resume Type</span>
                  <a-select
                    style="width: 100%"
                    class="filter-select"
                    :options="resume_options"
                    v-model:value="modelRef.resumeType"
                    :filter-option="false"
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div
                class="filter-form-elements left-form-elements"
                style="flex-basis: 30%"
              >
                <a-form-item>
                  <span class="input-label">City</span>
                  <a-select
                    show-search
                    style="width: 100%"
                    class="filter-select"
                    :options="city_options"
                    @search="fetchCityData"
                    v-model:value="modelRef.city"
                    :filter-option="false"
                    showArrow
                  >
                  </a-select>
                </a-form-item>
              </div>
              <div class="filter-form-elements" style="flex-basis: 30%">
                <a-form-item>
                  <span class="input-label">Designation</span>
                  <a-input
                    v-model:value="modelRef.designation"
                    class="form-input"
                  ></a-input>
                </a-form-item>
              </div>
            </div>
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 14.5rem"
          />
        </div>
        <div class="workflow-section">
          <div class="workflow-section-form">
            <div class="title">Workflow</div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Team</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    v-model:value="modelRef.teams"
                    :filter-option="false"
                    :options="team_tags_options"
                    @search="fetchTeamsData"
                    @change="fetchSubTeamsData(modelRef.teams)"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Sub Team</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    v-model:value="modelRef.subTeams"
                    :filter-option="false"
                    :options="sub_team_tags_options"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
            <div class="form-sub-section">
              <div
                style="flex-basis: 97%; margin-left: 1rem"
                class="filter-form-elements left-form-elements"
              >
                <a-form-item>
                  <span class="input-label">Procedure Stage</span>
                  <a-select
                    class="filter-select"
                    mode="tags"
                    v-model:value="modelRef.procedureStages"
                    :filter-option="false"
                    :options="procedure_stage_tags_options"
                    @search="fetchProcedureStageData;"
                  ></a-select>
                </a-form-item>
              </div>
            </div>
          </div>
          <a-divider
            type="vertical"
            class="vertical-divider"
            style="height: 22rem"
          />
        </div>
        <div class="footer">
          <a-button
            type="link"
            style="
              color: rgb(76, 81, 85);
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            key="back"
            @click="closeFilter"
            >Cancel</a-button
          >
          <a-button
            key="submit"
            style="
              background: RGB(228, 129, 106);
              color: #ffffff;
              border-radius: 15px;
              font-size: $font_size_12;
              font-family: Poppins;
              font-weight: 500;
            "
            @click="filterModalOk"
            >Apply Filter</a-button
          >
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { Form } from "ant-design-vue";
import { defineComponent, ref, onMounted, reactive } from "vue";
import Services from "../../../services/apis";
import Utils from "../../../utils";

const useForm = Form.useForm;

export default defineComponent({
  setup(props, context) {
    let paData;
    let subPaData;
    let company_name_options = ref([]);
    let vacancy_type_options = ref([]);
    let vacancy_status_options = ref([]);
    let lead_cic_options = ref([]);
    let originated_by_options = ref([]);
    let sector_tags_options = ref([]);
    let industry_tags_options = ref([]);
    let verticals_tags_options = ref([]);
    let practice_area_tags_options = ref([]);
    let sub_practice_area_tags_options = ref([]);
    let function_tags_options = ref([]);
    let sub_function_tags_options = ref([]);
    let sub_sub_function_tags_options = ref([]);
    let company_types_tags_options = ref([]);
    let education_degrees_options = ref("");
    let university_options = ref("");
    let resume_options = ref("");
    let city_options = ref("");
    let team_tags_options = ref([]);
    let sub_team_tags_options = ref([]);
    let procedure_stage_tags_options = ref([]);

    let modelRef = reactive({
      phone: "",
      email: "",
      name: "",
      vacancyId: "",
      title: "",
      status: ref([]),
      types: ref([]),
      fromDate: "",
      toDate: "",
      leadCics: ref([]),
      originatedBy: ref([]),
      industries: ref([]),
      sectors: ref([]),
      verticals: ref([]),
      practiceAreas: ref([]),
      subPracticeAreas: ref([]),
      functions: ref([]),
      subFunctions: ref([]),
      subSubFunctions: ref([]),
      companyTypes: ref([]),
      companyName: ref([]),
      degrees: ref(),
      institutes: ref(),
      resumeType: ref(),
      city: ref(),
      teams: ref([]),
      subTeams: ref([]),
      procedureStages: ref([]),
      designation: "",
    });
    let payload = {
      phone: "",
      email: "",
      name: "",
      vacancy: {
        vacancyId: "",
        title: "",
        status: ref([]),
        types: ref([]),
        fromDate: "",
        toDate: "",
        leadCics: ref([]),
        originatedBy: ref([]),
      },
      sectors: ref([]),
      industries: ref([]),
      verticals: ref([]),
      practiceAreas: ref([]),
      subPracticeAreas: ref([]),
      functions: ref([]),
      subFunctions: ref([]),
      subSubFunctions: ref([]),
      types: ref([]),
      companyName: ref([]),
      degrees: ref(),
      institutes: ref(),
      resumeType: ref(),
      city: ref(),
      teams: ref([]),
      subTeams: ref([]),
      procedureStages: ref([]),
      designation: "",
      vacancyFilter: "",
    };
    const { resetFields } = useForm(modelRef);

    const fetchVacancyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["VACANCY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          vacancy_type_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };
    const fetchVacancyStatusData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["VACANCY"] };
      Services.getVacancyStatusDetail(payload)
        .then((response) => {
          const { data } = response;
          vacancy_status_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSectorData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getSectorDetail(payload)
        .then((response) => {
          const { data } = response;
          sector_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchIndustryData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getIndustryDetail(payload)
        .then((response) => {
          const { data } = response;
          industry_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchVerticalData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          verticals_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchpracticeAreas = (value, id) => {
      if (id) {
        paData = id;
      }
      Services.getPracticeAreaDetail({
        mode: "AUTOCOMPLETE",
        q: value,
        types: ["PRACTICE_AREA"],
        verticals: paData ? paData : null,
      })
        .then((response) => {
          practice_area_tags_options.value = response.data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const fetchsubPracticeAreas = (value,id) => {
      if (id) {
        subPaData = id;
      }
      Services.getSubPracticeAreaDetail({
        mode: "AUTOCOMPLETE",
        types: ["SUB_PRACTICE_AREA"],
        practice_areas : subPaData ? subPaData : null,
        q: value,
      })
        .then((response) => {
          sub_practice_area_tags_options.value = response.data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const fetchFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubSubFunctionData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", type: ["SUB_SUB_FUNCTION"] };
      Services.getFunctionDetail(payload)
        .then((response) => {
          const { data } = response;
          sub_sub_function_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCompanyTypeData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["COMPANY_TYPES"] };
      Services.genderListing(payload)
        .then((response) => {
          const { data } = response;
          company_types_tags_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCompanyData = (value) => {
      let payload = {};
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getAccountListing(payload)
        .then((response) => {
          const { data } = response;
          let tempData = [];
          data.map((x) => {
            tempData.push({
              value: x.account.id,
              label: x.account.name,
            });
          });
          Object.assign(company_name_options.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchTeamsData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["TEAM"] };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          team_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchSubTeamsData = (value) => {
      Services.teamListing({
        mode: "AUTOCOMPLETE",
        types: ["SUB_TEAM"],
        teams: value,
      })
        .then((response) => {
          const { data } = response;
          sub_team_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchProcedureStageData = (value) => {
      let payload = {};
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE", types: ["PROCEDURE"] };
      Services.getProcedureTypeDetail(payload)
        .then((response) => {
          const { data } = response;
          procedure_stage_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchCityData = (value) => {
      Services.cityListing({ q: value, mode: "AUTOCOMPLETE" })
        .then((response) => {
          const { data } = response;
          city_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchResumeType = (value) => {
      Services.resumeListing({ types: ["RESUME"] })
        .then((response) => {
          const { data } = response;
          resume_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchInstitutesData = (value) => {
      Services.eduInstituteListing({ q: value, types: ["DEGREE"] })
        .then((response) => {
          const { data } = response;
          university_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchDegreeData = (value) => {
      Services.eduDegreesListing({ q: value, types: ["DEGREE"] })
        .then((response) => {
          const { data } = response;
          education_degrees_options.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchLeadCIC = (value) => {
      let user_listing_payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
      };

      Services.userListing(user_listing_payload)
        .then((response) => {
          const { data } = response;
          lead_cic_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchOriginatedBy = (value) => {
      let payload = {
        q: value,
        pageNo: 0,
        mode: "AUTOCOMPLETE",
      };

      Services.userListing(payload)
        .then((response) => {
          const { data } = response;
          originated_by_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    onMounted(() => {
      fetchVacancyTypeData();
      fetchVacancyStatusData();
      fetchVerticalData();
      fetchpracticeAreas();
      fetchsubPracticeAreas();
      fetchFunctionData();
      fetchSubFunctionData();
      fetchSubSubFunctionData();
      fetchSectorData();
      fetchIndustryData();
      fetchCompanyTypeData();
      fetchCompanyData();
      fetchTeamsData();
      fetchSubTeamsData();
      fetchProcedureStageData();
      fetchCityData();
      fetchResumeType();
      fetchInstitutesData();
      fetchDegreeData();
      fetchLeadCIC();
      fetchOriginatedBy();
    });

    function closeFilter() {
      context.emit("closeFilter", false);
    }

    function filterModalOk() {
      console.log("payload :>> ", modelRef);
      let tempObject = Utils.deepCopyObject(payload);

      modelRef.phone
        ? (tempObject["phone"] = modelRef.phone)
        : delete tempObject.phone;
      modelRef.email
        ? (tempObject["email"] = modelRef.email)
        : delete tempObject.email;
      modelRef.name
        ? (tempObject["name"] = modelRef.name)
        : delete tempObject.name;
      modelRef.vacancyId
        ? (tempObject.vacancy.vacancyId = modelRef.vacancyId)
        : delete tempObject.vacancy.vacancyId;
      modelRef.title
        ? (tempObject.vacancy.title = modelRef.title)
        : delete tempObject.vacancy.title;
      modelRef.status.length > 0
        ? (tempObject.vacancy.status = modelRef.status)
        : delete tempObject.vacancy.status;
      modelRef.fromDate
        ? (tempObject.vacancy.fromDate = Utils.displayYearFirst(
            modelRef.fromDate
          ))
        : delete tempObject.vacancy.fromDate;
      modelRef.toDate
        ? (tempObject.vacancy.toDate = Utils.displayYearFirst(modelRef.toDate))
        : delete tempObject.vacancy.toDate;
      modelRef.types.length > 0
        ? (tempObject.vacancy.types = modelRef.types.map((x) => x.value))
        : delete tempObject.vacancy.types;
      modelRef.leadCics.length > 0
        ? (tempObject.vacancy.leadCics = modelRef.leadCics)
        : delete tempObject.vacancy.leadCics;
      modelRef.originatedBy.length > 0
        ? (tempObject.vacancy.originatedBy = modelRef.originatedBy)
        : delete tempObject.vacancy.originatedBy;
      modelRef.sectors.length > 0
        ? (tempObject.sectors = modelRef.sectors)
        : delete tempObject.sectors;
      modelRef.industries.length > 0
        ? (tempObject.industries = modelRef.industries)
        : delete tempObject.industries;
      modelRef.verticals.length > 0
        ? (tempObject.verticals = modelRef.verticals)
        : delete tempObject.verticals;
      modelRef.practiceAreas.length > 0
        ? (tempObject.practiceAreas = modelRef.practiceAreas)
        : delete tempObject.practiceAreas;
      modelRef.subPracticeAreas.length > 0
        ? (tempObject.subPracticeAreas = modelRef.subPracticeAreas)
        : delete tempObject.subPracticeAreas;
      modelRef.functions.length > 0
        ? (tempObject.functions = modelRef.functions)
        : delete tempObject.functions;
      modelRef.subFunctions.length > 0
        ? (tempObject.subFunctions = modelRef.subFunctions)
        : delete tempObject.subFunctions;
      modelRef.subSubFunctions.length > 0
        ? (tempObject.subSubFunctions = modelRef.subSubFunctions)
        : delete tempObject.subSubFunctions;
      modelRef.companyTypes.length > 0
        ? (tempObject.types = modelRef.companyTypes)
        : delete tempObject.types;
      Object.keys(modelRef.companyName).length > 0
        ? (tempObject.companyName = modelRef.companyName.label)
        : delete tempObject.companyName;
      modelRef.degrees
        ? (tempObject.degrees = modelRef.degrees)
        : delete tempObject.degrees;
      modelRef.institutes
        ? (tempObject.institutes = modelRef.institutes)
        : delete tempObject.institutes;
      modelRef.resumeType
        ? (tempObject.resumeType = modelRef.resumeType)
        : delete tempObject.resumeType;
      modelRef.city
        ? (tempObject.city = modelRef.city)
        : delete tempObject.city;
      modelRef.designation
        ? (tempObject["designation"] = modelRef.designation)
        : delete tempObject.designation;
      modelRef.teams.length > 0
        ? (tempObject.teams = modelRef.teams)
        : delete tempObject.teams;
      modelRef.subTeams.length > 0
        ? (tempObject.subTeams = modelRef.subTeams)
        : delete tempObject.subTeams;
      modelRef.procedureStages.length > 0
        ? (tempObject.procedureStages = modelRef.procedureStages)
        : delete tempObject.procedureStages;
      if (Object.keys(tempObject.vacancy).length > 0) {
        tempObject["filters"] = JSON.stringify(tempObject.vacancy);
      } else {
        delete tempObject.vacancy;
        delete tempObject.filters;
      }
      console.log("tempObject :>> ", tempObject);
      context.emit("filterModalOk", tempObject, false);
      // resetFilter();
    }

    function resetFilter() {
      modelRef.phone = "";
      modelRef.email = "";
      modelRef.name = "";
      modelRef.vacancyId = "";
      modelRef.title = "";
      modelRef.status = ref([]);
      modelRef.types = ref([]);
      modelRef.fromDate = "";
      modelRef.toDate = "";
      modelRef.leadCics = ref([]);
      modelRef.originatedBy = ref([]);
      modelRef.sectors = ref([]);
      modelRef.industries = ref([]);
      modelRef.verticals = ref([]);
      modelRef.practiceAreas = ref([]);
      modelRef.subPracticeAreas = ref([]);
      modelRef.functions = ref([]);
      modelRef.subFunctions = ref([]);
      modelRef.subSubFunctions = ref([]);
      modelRef.companyTypes = ref([]);
      modelRef.companyName = ref([]);
      modelRef.degrees = ref();
      modelRef.institutes = ref();
      modelRef.resumeType = ref();
      modelRef.city = ref();
      modelRef.teams = ref([]);
      modelRef.subTeams = ref([]);
      modelRef.procedureStages = ref([]);
      modelRef.designation = "";
    }

    return {
      modelRef,
      resetFields,
      closeFilter,
      filterModalOk,
      resetFilter,

      // All select items options
      vacancy_type_options,
      vacancy_status_options,
      lead_cic_options,
      originated_by_options,
      sector_tags_options,
      industry_tags_options,
      verticals_tags_options,
      practice_area_tags_options,
      sub_practice_area_tags_options,
      function_tags_options,
      sub_function_tags_options,
      sub_sub_function_tags_options,
      company_types_tags_options,
      company_name_options,
      education_degrees_options,
      university_options,
      resume_options,
      city_options,
      team_tags_options,
      sub_team_tags_options,
      procedure_stage_tags_options,

      // Select Api call Funtions
      fetchVacancyTypeData,
      fetchVacancyStatusData,
      fetchVerticalData,
      fetchpracticeAreas,
      fetchsubPracticeAreas,
      fetchFunctionData,
      fetchSubFunctionData,
      fetchSubSubFunctionData,
      fetchSectorData,
      fetchIndustryData,
      fetchCompanyTypeData,
      fetchCompanyData,
      fetchInstitutesData,
      fetchDegreeData,
      fetchCityData,
      fetchTeamsData,
      fetchSubTeamsData,
      fetchProcedureStageData,
      fetchLeadCIC,
      fetchOriginatedBy,
    };
  },
});
</script>

<style lang="scss">
@import "../styles/filter.scss";
</style>
