<template>
  <div>
    <div class="vacancty-listing-main-wrapper">
      <div
        class="vacancy-listing-table-with-select-wrapper"
        :style="[rightSideBarVisibility ? ' width : 73%' : ' width : 100%']"
      >
        <div class="vacancy-listing-select-section-with-search">
          <div class="vacancy-listing-select-section">
            <div v-for="tag in tags" :key="tag.id" style="padding-left: 10px">
              <a-checkable-tag
                :checked="selectedTags.indexOf(tag.id) >= 0"
                @change="(checked) => handleChange(tag.id, checked)"
              >
                {{ tag.title }}
              </a-checkable-tag>
            </div>
          </div>

          <div class="vacancy-listing-search-and-filter-wrapper">
            <div class="vacancy-profile-filter-wrapper">
              <a-input-search
                v-model:value="vacancySearchValue"
                placeholder="search"
                @search="searchVacancy(vacancySearchValue)"
              />
            </div>
            <div class="vacancy-profile-search-wrapper">
              <a-button
                style="
                  height: 44px;
                  border-radius: 13px;
                  background: white;
                  border: none;
                  font-family: 'Poppins Medium';
                "
                type="ghost"
                @click="showVacancyFilters"
              >
                <FilterOutlined style="font-size: 18px" />
                Filters
              </a-button>
            </div>
          </div>
        </div>

        <div class="vacancy-listing-table-wrapper">
          <div class="vacancy-listing-table-select-section">
            <div
              v-for="type in types"
              :key="type.id"
              style="padding-left: 10px"
            >
              <a-checkable-tag
                :checked="selectedtype.indexOf(type.id) >= 0"
                @change="(checked) => handleTableChange(type.id, checked)"
              >
                {{ type.title }}
              </a-checkable-tag>
            </div>
          </div>
          <div class="vacancy-listing-table">
            <a-table
              :columns="vacancyColumnData"
              :data-source="vacancyData"
              :row-selection="vacancyListingRowSelection"
              :pagination="false"
              :rowKey="(record) => record.vacancy.id"
            >
              <template #vacancy="{ text }">
                <div
                  class="vacancy-title-in-table"
                  @click="vacancyProfile(text.id)"
                >
                  {{ text.vacancyName }}
                </div>
                <u class="vacancy-id-in-table">{{ text.vacancyId }}</u>
                <BarChartOutlined
                  class="vacancy-taxonomy-in-table"
                  @click="showVacancyTaxonomy(text.id)"
                />
              </template>

              <template #company="{ text }">
                <div style="display: flex">
                  <div v-if="text.logo" style="margin: 5px 5px 0px 0px">
                    <img src="{{text.logo}}" alt="Candidate image" />
                  </div>
                  <div v-else style="margin: 5px 5px 0px 0px">
                    <a-avatar>
                      <template #icon><UserOutlined /></template>
                    </a-avatar>
                  </div>
                  <div>
                    <div class="vacancy-title-in-table" @click="goToCompanyProfile(text.id)">{{ text.name }}</div>
                    <u class="vacancy-id-in-table">{{ text.companyId }}</u>
                    <BarChartOutlined
                      class="vacancy-taxonomy-in-table"
                      @click="showCompanyTaxonomy(text.id)"
                    />
                  </div>
                </div>
              </template>
              <template #salary="{ text }">
                <div class="vacancy-salary-in-table">{{ text }}</div>
              </template>
              <template #vacType="{ text }">
                <div class="vacancy-title-in-table">{{ text }}</div>
              </template>
              <template #vacStatus="{ text }">
                <div class="vacancy-title-in-table">{{ text }}</div>
              </template>
              <template #resumes="{ text }">
                <div class="vacancy-salary-in-table">{{ text }}</div>
              </template>
              <template #vacValue="{ text }">
                <div class="vacancy-salary-in-table">{{ text }}</div>
              </template>
              <template #taxonomy="{ text }">
                <div class="vacancy-salary-in-table">{{ text }}</div>
              </template>
              <template #customTitle>
                <a-button
                  @click="showColumnManager"
                  type="link"
                  style="color: #4c5155"
                >
                  <MenuOutlined />
                </a-button>
              </template>
            </a-table>
            <div class="vacancy-listing-view-more-wrapper">
              <div v-if="showViewMore">
                <div>
                  <span
                    class="vacancy-list-view-more-text"
                    @click="fetchMoreVacancies()"
                    ><PlusCircleOutlined />  View more</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="vacancyModalVisibility"
          :closable="false"
          centered
          footer=""
          destroyOnClose
        >
          <VacancyWidget :id="vacancyTaxonomyId" />
        </a-modal>
      </div>
      <div>
        <a-modal
          class="filter-modal"
          title=""
          width="500px"
          v-model:visible="companyModalVisibility"
          :closable="false"
          centered
          footer=""
          destroyOnClose
        >
          <CompanyWidget :id="accountTaxonomyId" />
        </a-modal>
      </div>
      <div>
        <a-modal
          class="column-modal"
          title=""
          v-model:visible="manageColumnsModalVisibility"
          :closable="false"
          centered
          @ok="columnManagerModalOk"
          width="500px"
        >
          <div>
            <div style="width=80%">
              <manageColumnComponent
                :masterList="columnData"
                @updateColumns="updateColumns"
              />
            </div>
          </div>
          <template #footer>
            <a-button> </a-button>
          </template>
        </a-modal>
        <a-modal
          class="filter-modal"
          title=""
          v-model:visible="vacancyFilterVisibility"
          :closable="false"
          centered
          width="1200px"
        >
          <div>
            <div style="width=80%">
              <Filter
                @closeFilter="vacancyFilterModalCancel"
                @filterModalOk="vacancyFilterModalOk"
              />
            </div>
          </div>
          <template #footer>
            <a-button style="margin: 0.5rem 0"> </a-button>
          </template>
        </a-modal>
      </div>
      <div>
        <LeftCircleTwoTone
          v-if="!rightSideBarVisibility"
          @click="showRightSideBar"
          style="color: blue; font-size: 1.5rem"
        />
        <RightCircleTwoTone
          v-if="rightSideBarVisibility"
          @click="showRightSideBar"
          style="
            font-size: 1.5rem;
            position: fixed;
            z-index: 9;
            transform: translateX(-10px);
          "
        />
      </div>
      <div class="action-bar">
        <RightSideBar @updateVacancyListing="updateListing" />
      </div>
    </div>
  </div>
</template>
<script>
import Services from "@/services/apis";
import {
  FilterOutlined,
  PlusCircleOutlined,
  UserOutlined,
  BarChartOutlined,
  MenuOutlined,
  LeftCircleTwoTone,
  RightCircleTwoTone,
} from "@ant-design/icons-vue";

import { ref, reactive, toRefs, onMounted } from "vue";
import VacancyWidget from "./widget.vue";
import CompanyWidget from "../../company/components/widget.vue";
import { useRouter } from "vue-router";
import Utils from "@/utils";
import RightSideBar from "./RightSideBar.vue";
import manageColumnComponent from "../../common/components/manageColumns.vue";
import Filter from "./Filter.vue";
import { useStore } from "vuex";

export default {
  components: {
    RightSideBar,
    FilterOutlined,
    MenuOutlined,
    PlusCircleOutlined,
    BarChartOutlined,
    UserOutlined,
    VacancyWidget,
    CompanyWidget,
    manageColumnComponent,
    Filter,
    LeftCircleTwoTone,
    RightCircleTwoTone,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      fetchVacancyListing();
    });
    const router = useRouter();
    const vacancyProfile = (id) => {
      router.push(`/vacancies/${id}`);
    };
    let showViewMore = ref(false);
    let vacancyDataCount = ref();
    let accountTaxonomyId = ref("");
    let vacancyTaxonomyId = ref("");
    const vacancyModalVisibility = ref(false);
    const companyModalVisibility = ref(false);
    const vacancySearchValue = ref("");
    let pageCount = 0;
    let filterDataObject;
    let rightSideBarVisibility = ref(false);
    const showVacancyTaxonomy = (vacancyId) => {
      vacancyTaxonomyId.value = vacancyId;
      vacancyModalVisibility.value = true;
    };

    const showCompanyTaxonomy = (accountId) => {
      accountTaxonomyId.value = accountId;
      companyModalVisibility.value = true;
    };
    let manageColumnsModalVisibility = ref(false);
    const columnManagerModalOk = (e) => {
      manageColumnsModalVisibility.value = false;
    };
    const showRightSideBar = () => {
      rightSideBarVisibility.value = !rightSideBarVisibility.value;
    };

    const vacancyTimeline = reactive({
      tags: [
        { id: "TODAY", title: "Today" },
        { id: "3D", title: "3D" },
        { id: "W", title: "W" },
        { id: "M1", title: "M" },
        { id: "Q1", title: "Q1" },
        { id: "Q2", title: "Q2" },
        { id: "Q3", title: "Q3" },
        { id: "Q4", title: "Q4" },
        { id: "Y2", title: "Y" },
        { id: "Y1", title: "Y-1" },
      ],
      selectedTags: ["TODAY"],
    });
    const vacancyTableTimeline = reactive({
      types: [
        { id: "ALL", title: "All" },
        { id: "MY_VACANCIES", title: "My Vacancies" },
        { id: "TEAM_VACANCIES", title: "Team Vacancies" },
        { id: "SITE_VACANCIES", title: "Site Vacancies" },
      ],
      selectedtype: ["ALL"],
    });

    let vacancyData = reactive([]);
    const vacancyFilterVisibility = ref(false);

    const showVacancyFilters = () => {
      vacancyFilterVisibility.value = true;
    };
    const vacancyFilterModalOk = (payload, value) => {
      pageCount = 0;
      fetchVacancyListing(payload);
      vacancyFilterVisibility.value = value;
      filterDataObject = Utils.deepCopyObject(payload);
    };

    const vacancyFilterModalCancel = (value) => {
      vacancyFilterVisibility.value = value;
    };

    let vacancyColumnData = reactive([
      {
        title: "Vacancy",
        dataIndex: "vacancy",
        key: "vacancy",
        slots: { customRender: "vacancy" },
      },
      {
        title: "Company",
        dataIndex: "company",
        key: "company",
        slots: { customRender: "company" },
      },
      {
        title: "#Of Resumes ",
        dataIndex: "resumes",
        key: "resumes",
        slots: { customRender: "resumes" },
        align: "center",
        width: 90,
      },
      {
        title: " Vac.Type",
        dataIndex: "vacType",
        key: "vacType",
        slots: { customRender: "vacType" },
        width: 90
      },
      {
        title: "Vac.Status",
        dataIndex: "vacStatus",
        key: "vacStatus",
        slots: { customRender: "vacStatus" },
        width: 120,
      },
      {
        title: "Salary",
        dataIndex: "salary",
        key: "salary",
        slots: { customRender: "salary" },
        width: 100,
      },
      {
        title: "Vac.Value",
        dataIndex: "vacValue",
        key: "vacValue",
        slots: { customRender: "vacValue" },
      },
      {
        title: "Taxonomy",
        dataIndex: "taxonomy",
        key: "taxonomy",
        slots: { customRender: "taxonomy" },
      },
      {
        colSpan: 1,
        width: 0,
        key: "manageColumns",
        slots: { title: "customTitle" },
      },
    ]);
    let columnData = reactive([
      {
        id: "vacancy_details",
        constants: "VACANCY_DETAILS",
        isVisible: true,
        name: "Vacancy Details",
        children: [
          {
            name: "Vacancy ID",
            id: "vacancy",
            isSelecetd: true,
          },
          {
            name: "Company Name",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Vac. Title",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Vac. Title",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Vac. Status",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Vac. Value",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Salary",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Start Date",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Display Start Date",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "Display End Date",
            id: "vacancy",
            isSelecetd: false,
          },
          {
            name: "# of Resumes",
            id: "vacancy",
            isSelecetd: false,
          },
        ],
      },

      {
        id: "taxonomy",
        constants: "TAXONOMY",
        isVisible: true,
        name: "Taxonomy",
        children: [
          {
            name: "Industry",
            id: "taxonomy",
            isSelecetd: true,
          },
          {
            name: "Sector",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "Practice Area",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "SPA",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "Function",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "SF Work Area",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "SSF Tag",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "Product",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "Region",
            id: "taxonomy",
            isSelecetd: false,
          },
          {
            name: "Segment",
            id: "taxonomy",
            isSelecetd: false,
          },
        ],
      },
      {
        id: "other",
        constants: "OTHER",
        isVisible: true,
        name: "Other",
        children: [
          {
            name: "RM",
            id: "other",
            isSelecetd: true,
          },
          {
            name: "Team",
            id: "other",
            isSelecetd: true,
          },
          {
            name: "Sub Team",
            id: "other",
            isSelecetd: false,
          },
          {
            name: "My Sub Team",
            id: "other",
            isSelecetd: false,
          },
          {
            name: "Leac CIC",
            id: "other",
            isSelecetd: false,
          },
          {
            name: "Originated By",
            id: "other",
            isSelecetd: true,
          },
          {
            name: "Upload",
            id: "other",
            isSelecetd: true,
          },
        ],
      },
    ]);

    const vacancyListingRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        store.dispatch("vacancyStore/updateSelectedVacancies", selectedRowKeys);
        selectedRowKeys = [];
      },
    };
    const handleChange = (tag, checked) => {
      const { selectedTags, tags } = vacancyTimeline;
      const nextSelectedTags = checked
        ? tags.filter((t) => t.id === tag)
        : selectedTags.filter((t) => t.id !== tag);
      console.log("nextSelectedTags", nextSelectedTags);
      vacancyTimeline.selectedTags = nextSelectedTags[0].id;
    };

    const handleTableChange = (type, checked) => {
      const { selectedtype, types } = vacancyTableTimeline;
      const nextSelectedTypes = checked
        ? types.filter((t) => t.id === type)
        : selectedtype.filter((t) => t.id !== type);
      console.log("nextSelectedTypes", nextSelectedTypes);
      vacancyTableTimeline.selectedtype = nextSelectedTypes[0].id;
    };

    const fetchVacancyListing = async (value) => {
      let listData = [];
      let q = "";
      let payload = {
        q: "",
        pageNo: pageCount,
        mode: "LISTING",
      };
      if (pageCount > 0) {
        listData = vacancyData;
        // vacancyData.splice(0);
      } else {
        vacancyData.splice(0);
      }
      if (typeof value === "string") {
        console.log(listData);
        payload = { q: value, pageNo: pageCount, mode: "LISTING" };
      } else if (typeof value === "object") {
        payload = {
          ...value,
          pageNo: pageCount,
          mode: "LISTING",
        };
      }

      await Services.getVacancyListing(payload)
        .then((response) => {
          const { data } = response;

          console.log('data VAC list:>> ', data);
          data &&
            data[0].count &&
            data[0].vacancies.map((x) => {
              listData.push({
                vacancy: {
                  id: x.vacancy.id,
                  vacancyName: x.vacancy.title ? x.vacancy.title : "-",
                  vacancyId: x.vacancy.vacancyId ? x.vacancy.vacancyId : "-",
                },
                company: {
                  id: x.account.id,
                  logo: x.account.logo,
                  name: x.account.name ? x.account.name : "-",
                  companyId: x.account.companyId ? x.account.companyId : "-",
                },
                resumes: x.resumes ? x.resumes : "-",
                vacType: x.vacancyType ? x.vacancyType : "-",
                vacStatus: x.vacancyStatus.name ? x.vacancyStatus.name : "-",
                vacValue: x.vacancy.value ? Utils.currencyFormat(x.vacancy.value) : 0,
                salary: x.salary && x.salary.from ? Utils.currencyFormat(x.salary.from) : 0,
                taxonomy: x.taxonomy ? x.taxonomy : "-",
              });
            });
          vacancyDataCount.value = data[0].count;
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
      Object.assign(vacancyData, listData);
      showViewMore.value = vacancyDataCount.value > vacancyData.length;
    };

    const fetchMoreVacancies = () => {
      pageCount++;
      if (filterDataObject && Object.keys(filterDataObject).length > 0) {
        fetchVacancyListing(filterDataObject);
      } else if (vacancySearchValue.value) {
        fetchVacancyListing(vacancySearchValue.value);
      } else {
        fetchVacancyListing();
      }
    };

    function updateColumns(masterList, status) {
      columnData = masterList;
      manageColumnsModalVisibility.value = status;
      let newColumnData = [];
      candidateListingColumns.map((column, ind) => {
        columnData.map((parent) => {
          let filterData = parent.children.filter(
            (obj) => obj.isSelecetd === true && obj.id === column.dataIndex
          );
          if (filterData.length > 0) {
            newColumnData.push(column);
          }
        });
      });
      console.log("newColumnData :>> ", newColumnData);
      Object.assign(listedCandidateColumns, newColumnData);
    }

    const searchVacancy = (value) => {
      pageCount = 0;
      filterDataObject = {};
      fetchVacancyListing(value);
    };

    const showColumnManager = () => {
      manageColumnsModalVisibility.value = true;
    };

    const goToCompanyProfile = (id) => {
      router.push(`/accounts/${id}/detail`);
    }

    const updateListing = (value) => {
      if (value) {
        fetchVacancyListing();
      }
    };
    return {
      ...toRefs(vacancyTimeline),
      ...toRefs(vacancyTableTimeline),
      rightSideBarVisibility,
      showRightSideBar,
      handleChange,
      handleTableChange,
      vacancyColumnData,
      vacancyData,
      vacancyListingRowSelection,
      fetchVacancyListing,
      fetchMoreVacancies,
      vacancyModalVisibility,
      // widget_id,
      showVacancyTaxonomy,
      vacancyProfile,
      companyModalVisibility,
      showCompanyTaxonomy,
      manageColumnsModalVisibility,
      columnManagerModalOk,
      columnData,
      updateColumns,
      showColumnManager,
      vacancyFilterVisibility,
      showVacancyFilters,
      vacancyFilterModalOk,
      vacancyFilterModalCancel,
      vacancySearchValue,
      searchVacancy,
      pageCount,
      showViewMore,
      vacancyDataCount,
      vacancyTaxonomyId,
      accountTaxonomyId,
      updateListing,
      goToCompanyProfile
    };
  },
};
</script>
<style lang="scss">
@import "../styles/VacancyList.scss";
</style>
